.tp-integer-select-input-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  height: 35px;
  width: min-content;
}

.tp-integer-select-input {
  width: min-content;
  border: none;
  background: none;
  margin: 0;
}

.tp-integer-select-input::placeholder {
  color: #BFBFBF;
}

.tp-integer-select-arrow-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tp-integer-select-arrow-button {
  border: none;
  background: none;
  height: 50%;
  width: 100%;
  font-size: 24px;
}

.tp-integer-select-arrow-button:hover {
  background-color: #BFBFBF;
}