#merge-customers-master-container {
  padding-left: 16px;
  padding-right: 16px;
  overflow: auto;
  max-height: 85vh;
}

.accordion-search {
  width: 100%;
  height: 36px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f4f4;
  padding: 8px;
  border-radius: 4px;
}

.merge-customers-search-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
}

.customer-search-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

#merge-customers-search-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  gap: 8px;
  border: 2px solid gainsboro;
}

#merge-customers-tab-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.customer-edit-info-tab {
  background-color: #ebefff;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  margin-top: 16px;
  padding: 16px;
  justify-content: space-between;
  width: 60%;
}

.merge-customers-text-area {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 8px;
}

textarea {
  width: 100%;
}

.customer-info-tab {
  background-color: #ebefff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  border-radius: 8px;
  margin-top: 16px;
  padding: 16px;
  width: 50%;
}

.edit-button {
  border: none;
  background: none;
  margin: 16px;
}

#merge-customers-conflict-resolve-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

#merge-customers-conflict-resolve {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  gap: 16px;
}

#merge-customers-conflict-colors {
  display: flex;
  align-items: center;
  gap: 16px;
}

#conflict-colors-container {
  display: flex;
  align-items: center;
  gap: 32px;
}

.conflict-color-help {
  display: flex;
  align-items: center;
  gap: 8px;
}

.merge-customers-data-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.conflict-value-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#merge-confirm-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.merge-customers-text-area {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.merge-edit-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  gap: 16px;
}

#merge-customers-header-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
}

#merge-customers-contacts-data-table {
  margin: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
