:root {
  --vertical-tabs-border-color: #e0e0e0;
  --vertical-tabs-bg-color: #f9f9f9;
  --vertical-tabs-active-bg-color: #f0f4ff;
  --vertical-tabs-text-color: #333;
  --vertical-tabs-active-text-color: #333;
  --upload-button-bg-color: #a00095;
  --upload-button-hover-bg-color: #8a007d;
}

.vertical-tabs {
  display: flex;
  width: 100%;
  height: 100%;
}

.vertical-tabs__list {
  min-width: 200px;
  border-right: 1px solid var(--vertical-tabs-border-color);
  display: flex;
  flex-direction: column;
  padding: 16px 0;
}

.vertical-tabs__tab-button {
  padding: 12px 16px;
  text-align: left;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  color: var(--vertical-tabs-text-color);
  transition: background-color 0.3s;
  /* text-decoration: underline; */
}

.vertical-tabs__tab-button--active {
  background-color: var(--vertical-tabs-active-bg-color);
  color: var(--vertical-tabs-active-text-color);
  font-weight: bold;
  text-decoration: none;
}

.vertical-tabs__tab-button:hover {
  background-color: #f3f3f3;
}

.vertical-tabs__content {
  padding: 24px;
  flex: 1;
}
