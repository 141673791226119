.tp-custom-autocomplete-list {
  position: absolute;
  min-width: 400px;
  max-height: 256px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow-y: scroll;
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  top: 100%;
  left: 0;
  z-index: 800;
}

.tp-custom-autocomplete-icon {
  cursor: pointer;
  border-radius: 4px,;
  border: 1px solid gainsboro;
  border-left: none;
  display: flex;
  align-items: center;
  padding: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: white;
}