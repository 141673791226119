.file-upload-box {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  position: relative;
}

.success-icon {
  color: green;
  font-size: 28px;
  margin-right: 12px;
}

.file-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.file-name {
  font-weight: bold;
}

.file-status {
  color: green;
  font-size: 14px;
  margin: 0;
}

.upload-button {
  border: 1px solid #8e44ad;
  background-color: transparent;
  color: #8e44ad;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 24px;
}

.upload-button:hover {
  background-color: #8e44ad;
  color: #fff;
}

.remove-icon {
  font-size: 24px;
  color: #666;
  cursor: pointer;
  position: absolute;
  right: 12px;
}

.remove-icon:hover {
  color: #e74c3c;
}

.logo-preview {
  margin-top: 16px;
  text-align: left;
}

.preview-image {
  max-width: 100px;
  max-height: 50px;
  display: block;
  margin-top: 8px;
}
