.tp-branch-selector-option {
  background-color: white;
  width: 100%;
  border: none;
  text-align: start;
  padding: 8px;
  flex: 1;
  border-bottom: 1px solid #bfbfbf;
}

.tp-branch-selector-option:hover {
  background-color: #780096;
  color: white;
}

.tp-branch-selector-option-alt {
  color: #6f6f6f;
  font-size: 12px;
}

.tp-branch-selector-option:hover .tp-branch-selector-option-alt {
  background-color: #780096;
  color: white;
}