.task-reminder {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  background: none;
  border: none;
  text-align: start;
  justify-content: start;
}

.task-reminder:disabled {
  text-decoration: none;
  opacity: .75;
  cursor: default;
}

.new-task-reminder {
  border: none;
  background: none;
  font-weight: 700;
}

.task-reminder:hover .new-task-reminder {
  text-decoration: underline;
}

.task-reminder:hover .task-reminder-info {
  text-decoration: underline;
}

.reminder-button {
  border-radius: 50%;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  aspect-ratio: 1;
  color: purple;
}

.reminder-button:hover {
  background-color: purple;
  color: white;
}

.reminder-modal-content {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
  width: 100%;
  height: fit-content;
}

.reminder-form-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
}

.task-reminder-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.task-reminder-delete-button {
  border: none;
  background: none;
  color: #780096;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.task-reminder-button-pair {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  gap: 16px;
}