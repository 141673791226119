.inbound-mailboxes-tab {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 75%;
    height: 100%;
  }

.inbound-mailboxes-with-icon-field {
  display: "flex";
  flex-direction: "row";
  align-items: "flex-end";
  margin-right: "-40px";
}