.tp-tooltip {
  position: relative;
  width: min-content;
}

.tp-tooltip .tp-tooltip-text {
  visibility: hidden;
  position: absolute;
  background-color: #780096;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
  top: 100%;
  left: 50%;
  text-wrap: balance;
  border-radius: 4px;
  z-index: 50;
}

.tp-tooltip:hover .tp-tooltip-text {
  visibility: visible;
}