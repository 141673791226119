.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  height: 350px;
}

.key-points-container {
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  overflow: auto;
  gap: 4px;
}

.copy-button {
  border: none;
  background-color: white;
}

.key-points-input {
  width: 100%;
  height: 175px;
  resize: none;
}

.overwrite-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.language-driver-options {
  width: 100%;
}

.language-driver-select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.language-driver-select {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid gray;
  min-height: 100%;
  height: 100%;
  width: 50%;
  background-color: white;
  padding: 0.5rem 1rem;
}

.process-button {
  color: white;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0.5rem 1rem;
  height: 100%;
}

.ai-unavailable {
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.compose-with-ai-alert {
  width: min-content;
  margin-bottom: 8px;
}

.compose-with-ai-content {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  width: min-content;
  white-space: nowrap;
}

.compose-with-ai-label {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.compose-with-ai-button {
  border: none;
  color: white;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  height: 100%;
}

.outbound-mail-main-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.outbound-mail-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid gray;
  padding: 8px;
  align-items: stretch;
  gap: 16px;
  overflow-y: auto;
}

.outbound-mail-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid gray;
  padding: 8px;
  align-items: stretch;
}

.output-email {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.keypoints-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.composed-email-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#tpgenai-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}
