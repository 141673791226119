.list-admin-main {
  padding: 16px;
    padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
      overflow: auto;
}

.list-admin-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
}

@media (max-width: 800px) {
  .form-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
}

.language-option {
  height: 32px;
  display: flex;
  align-items: center;
}

.language-button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

.list-admin-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.option-button-pair {
  display: flex;
  align-items: center;
  gap: 16px;
}

.draggable-list-item {
  display: flex;
  align-items: center;
  padding: 8px;
}

.draggable-menu {
  background-color: white;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 105%;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
}

.draggable-menu-item {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  width: 100%;
}

.draggable-menu-item:hover {
  background-color: #FFB8DC;
}
.delete-list-alert-modal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.delete-list-alert-button-pair {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}