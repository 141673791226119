.tab-label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
}

.tab-title {
  font-size: 16px;
  display: flex;
  align-items: center;
  line-height: 1.2;
  margin: 0;
}

.switch-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}
