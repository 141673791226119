.form-switch .form-check-input:checked {
  background-color: #009A58 !important;
  outline: none;
  border: none;
}

.form-switch .form-check-input:not(:checked) {
  outline: none !important;
  border: 1px solid #009A58 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23009A58'/%3e%3c/svg%3e") !important;
}