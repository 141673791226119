.scripts-main-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    min-height: 82vh;
  gap: 24px;
}

.script-admin-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}
.script-tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 75%
}

.script-form-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-end;
}

.script-management-footer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  border-top: 1px solid #BFBFBF;
  padding-top: 32px;
}

.script-footer-buttons-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 16px;
  justify-content: flex-end;
}

.step-additional-data-item {
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  width: 100%;
}

.scripts-steps-table-row:nth-child(odd) {
  background-color: #F4F4F4;
}
.additional-data-logic-step-table {
  background-color: white;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  width: 100%;
}
.step-additional-data-item-add-button {
  border: 1px solid purple;
  background-color: white;
  color: purple;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  display: flex;
    align-items: center;
    justify-content: center;
}
.logic-determination-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: #EEF2FF;
  border-radius: 4px;
  padding: 24px;
  gap: 32px;
}
.preview-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #D3E1FC;
  color: #002C81;
  padding: 16px;
  gap: 16px;
  border-radius: 5px;
}
.localized-script-names-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.add-data-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: min-content;
  padding: 8px;
  white-space: nowrap;
  background-color: white;
  border: none;
}

.add-data-option:nth-child(2n+1) {
  background-color: #efeaef;
}

.add-data-option:hover {
  background-color: #b9b4ba;
}
.script-add-data-table-row {
  background-color: white;
}

.script-add-data-table-row:nth-child(2n+2) {
  background-color: #efeaef;
}
.preview-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.outer-preview-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  white-space: pre-wrap;
  border-radius: 4px;
}

.preview-form-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: initial;
  height: max-content;
}

.next-button {
  padding-left: 16px;
  padding-right: 16px;
  justify-self: flex-start;
}

.preview-input {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  margin-bottom: 16px;
  justify-content: flex-start;
}

.line-breaker {
  width: 100%;
}

.tree-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: min-content;
  padding: 8px;
  gap: 8px;
  white-space: nowrap;
  background-color: white;
  border: none;
}

.tree-option:nth-child(2n+1) {
  background-color: #efeaef;
}

.tree-option:hover {
  background-color: #b9b4ba;
}
.tooltip-help {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: none;
  color: purple;
  border: none;
  cursor: default;
}

.step-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  text-wrap: nowrap;
}
.step-buttons-container {
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
  align-items: center;
}

.step-edit-button {
  background: none;
  border: none;
  color: #780096;
}
.steps-list-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.steps-list-menu-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: white;
}

.steps-list-menu-option:hover {
  background-color: #FFB8DC;
}
.invalid-script-step-data-input {
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}