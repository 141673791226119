.TNQ-master-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 32px;
    overflow: auto;
}

#TNQ-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #bfbfbf;
    padding: 8px;
    border-radius: 5px;
}

#TNQ-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    gap: 8px;
}

#TNQ-timer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    height: 24px;
    border-radius: 4px;
}

.TQN-radioGroup-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    isolation: isolate;
    width: 100%;
    background: #F5F5F5;
    border-radius: 8px;
}

.TNQ-customField-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    margin-bottom: 10px;
}

.TNQ-customField-Container-inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    width: 100%;
}

.TNQ-informativeFields-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
}

.TNQ-informativeFields-Container-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 48px;
    width: 100%;
}

.TNQ-informativeField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
}