.inbound-rules-def-main-form-container {
  width: 65%;
  flex-grow: 1;
  background-color: white;
  border-radius: 8px;
}

.inbound-rules-def-main-form-inner-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  margin-top: 10px !important;
  height: -1vh;
}

.inbound-rules-def-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
}

.inbound-rules-def-form-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  gap: 24px;
}

.inbound-rules-def-option-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

/* Table styles matching OrganizationsRelations */
.inbound-rules-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 16px;
}

.inbound-rules-table th,
.inbound-rules-table td {
  padding: 12px;
  border: 1px solid #dee2e6;
  vertical-align: middle;
}

.inbound-rules-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  text-align: left;
}

.inbound-rules-table tbody tr:hover {
  background-color: #f8f9fa;
}

/* Form elements spacing */
.form-group {
  margin-bottom: 24px;
  width: 100%;
}

.tp-textbox-container,
.tp-select-container {
  width: 100%;
}

/* Media queries */
@media (max-width: 1000px) {
  .inbound-rules-def-main-form-container {
    width: 90%;
  }
  
  .inbound-rules-def-form-row {
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 16px;
  }
}

/* Section title styles */
.inbound-rules-section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #2E2E2E;
}

/* Button styles */
.inbound-rules-def-cancel-button {
  background-color: white;
  color: #780096;
  padding: 11px 16px;
}

/* Radio group alignment */
.tp-radio-group-horizontal {
  display: flex;
  gap: 16px;
  align-items: center;
}

/* Query expression section */
.query-expression-container {
  background-color: #f8f9fa;
  padding: 16px;
  border-radius: 4px;
  margin-top: 16px;
}