.tp-radio-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

input[type="radio"]:checked {
  accent-color: #009a58;
}
