.tp-icon-styled-select-container {
  position: absolute;
  display: inline-block;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  width: 297px;
  height: 0;
  visibility: hidden;
  opacity: 0;
  scale: .9;
  z-index: 25;
  transform-origin: top;
  top: 105%;
}

.tp-icon-styled-select-container.shown {
  visibility: visible;
  opacity: 1;
  scale: 1;
  height: fit-content;
  transition: all .25s ease;
}

.tp-icon-styled-select-floating-menu {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: start;
  gap: 16px;
  width: 297px;
  background-color: white;
  border-radius: 4px;
  z-index: 50;
  padding: 16px;
  height: min-content;
  max-height: 395px;
  overflow-y: scroll;
  align-content: start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.tp-icon-styled-select-icon-button {
  border: none;
  background-color: white;
  color: black;
  border-radius: 50%;
  aspect-ratio: 1;
}

.tp-icon-styled-select-icon-button:hover {
  color: white;
  background-color: #780096;
}

.tp-icon-styled-select-query-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid #BFBFBF;
  padding: 0 8px;
}

.tp-icon-styled-select-query-input {
  width: 100%;
  border: none;
  padding: 8px;
  background-color: white;
}

.tp-icon-styled-select-main-input {
  width: 100%;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 8px;
}

.tp-icon-styled-select-main-input-button {
  gap: 8px;
  width: 100%;
  text-align: start;
  border: none;
  color: #808080;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
}

.tp-icon-styled-select-main-input-button:hover .tp-icon-styled-select-remove-button {
  visibility: visible;
}

.tp-icon-styled-select-remove-button:hover {
  background-color: #780096;
  color: white;
}

.tp-icon-styled-select-remove-button {
  visibility: hidden;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: #BFBFBF;
  color: white;
  width: 14px;
  height: 14px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 7px;
  top: 45%;
  border: none;
}