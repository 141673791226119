.circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  /* Create a circle by setting border-radius to 50% */
  background-color: #f1ebeb;
  /* Circle background color */
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number {
  font-size: 24px;
  /* Adjust font size as needed */
  color: #1a1717;
  /* Text color */
}

.active-color {
  color: rgb(160, 0, 149);
  background: #f4f4f4;
  padding: 6px 14px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}

/* .scroll-container {
    max-height: 720px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
} */
