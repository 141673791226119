.customer-type-def-new-select {
    position: absolute;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    z-index: 50;
    width: 158px;
    margin-top: 8px;
    border-radius: 4px;
    opacity: 0;
    scale: .9;
    transform-origin: top right;
    visibility: hidden;
  }
    
  .customer-type-def-new-select.shown {
    visibility: visible;
    scale: 1;
    opacity: 1;
    transition: all .25s ease;
  }
    
  .customer-type-def-main-form-container {
    width: 65%;
    flex-grow: 1;
  }
  
  .customer-type-def-main-form-inner-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    margin-top: 10px !important;
    height: 80vh;
  }
    
  @media (max-width: 1000px) {
    .customer-type-def-main-form-container {
      width: 90%;
    }
  }
    
  .customer-type-def-language-button {
    color: #2E2E2E;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    text-wrap: nowrap;
  }
    
  .customer-type-def-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    width: 100%;
  }
  
  .customer-type-def-new-select-button {
    border: none;
    background-color: white;
    width: 100%;
    padding: 8px;
    text-align: start;
  }
  
  .customer-type-def-new-select-button:hover {
    background-color: #E6E6E6;
  }
  .customer-type-def-link-button {
    border: none;
    background: none;
    color: #780096;
    text-decoration: underline;
  }
  
  .customer-type-def-selected-profiles-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .customer-type-def-option-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  
  .customer-type-def-cancel-button {
    background-color: white;
    color: #780096;
    padding: 11px 16px;
  }
  
  .customer-type-def-form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    gap: 24px;
  }
  
  @media (max-width: 1000px) {
    .customer-type-def-form-row {
      flex-direction: column;
    }
  }
  
  .customer-type-def-autocomplete-button {
    text-align: start;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: none;
    padding: 4px 16px;
    width: 100%;
  }
  
  .customer-type-def-autocomplete-button:hover {
    background-color: #780096;
    color: white;
  }
  
  .customer-type-def-reorder-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
  
  .customer-type-def-reorder-modal-title {
    color: #2E2E2E;
    font-size: 18px;
    font-weight: 700;
  }
  
  .customer-type-def-reorder-modal-info {
    display: flex;
    flex-direction: row;
    background-color: #D3E1FC;
    color: #002C81;
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    border-radius: 5px;
    gap: 16px;
  }
  
  .customer-type-def-reorderable-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F4F4F4;
    flex-direction: row;
    border-radius: 4px;
    padding: 8px;
    margin: 8px 0px;
  }
  
  .customer-type-def-group-drag-icon {
    visibility: hidden;
  }
  
  .customer-type-def-reorderable-group:hover .customer-type-def-group-drag-icon {
    visibility: visible;
  }
  
  .customer-type-def-item-drag-icon {
    visibility: hidden;
  }
  
  .customer-type-def-reorderable-item:hover .customer-type-def-item-drag-icon {
    visibility: visible;
  }
  
  .customer-type-def-header-preview-button {
    border: none;
    background: none;
    z-index: 20;
  }
  
  .customer-type-def-group-dnd {
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    display: none;
    transition: max-height .1s ease;
  }
  
  .customer-type-def-group-dnd.shown {
    min-height: 10px;
    max-height: 500px;
    overflow: auto;
    display: flex;
    transition: max-height .5s ease;
  }
  
  .customer-type-def-reorderable-item {
    padding: 4px;
    padding-left: 5%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }