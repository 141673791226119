.tp-avatar {
  display: inline-block;
  overflow: hidden;
  background-color: #f0f0f0;
  cursor: pointer;
}

.tp-avatar-circular {
  border-radius: 50%;
}

.tp-avatar-square {
  border-radius: 8px;
}

.tp-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tp-avatar-placeholder {
  width: 100%;
  height: 100%;
  background-color: white;
}

.tp-avatar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.tp-avatar-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
  max-height: 90vh;
  padding: 10px;
}

.tp-avatar-modal-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.tp-avatar-modal-content:hover {
  cursor: pointer;
}
