.groups-main-container {
padding-left: 16px;
padding-right: 32px;
}

.groups-tab {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 75%;
    height: 100%;
}