.tp-chip {
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 12px;
  padding: 10px;
  height: 24px;
  width: max-content;
}
