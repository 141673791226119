.menu-def-new-select {
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  z-index: 50;
  width: 158px;
  margin-top: 8px;
  border-radius: 4px;
  opacity: 0;
  scale: .9;
  transform-origin: top right;
  visibility: hidden;
}
  
.menu-def-new-select.shown {
  visibility: visible;
  scale: 1;
  opacity: 1;
  transition: all .25s ease;
}
  
.menu-def-main-form-container {
  width: 65%;
  flex-grow: 1;
}

.menu-def-main-form-inner-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;
  height: 80vh;
}
  
@media (max-width: 1000px) {
  .menu-def-main-form-container {
    width: 90%;
  }
}
  
.menu-def-language-button {
  color: #2E2E2E;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  text-wrap: nowrap;
}
  
.menu-def-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
}

.menu-def-new-select-button {
  border: none;
  background-color: white;
  width: 100%;
  padding: 8px;
  text-align: start;
}

.menu-def-new-select-button:hover {
  background-color: #E6E6E6;
}
.menu-def-link-button {
  border: none;
  background: none;
  color: #780096;
  text-decoration: underline;
}

.menu-def-selected-profiles-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.menu-def-option-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.menu-def-cancel-button {
  background-color: white;
  color: #780096;
  padding: 11px 16px;
}

.menu-def-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  gap: 24px;
}

@media (max-width: 1000px) {
  .menu-def-form-row {
    flex-direction: column;
  }
}

.menu-def-autocomplete-button {
  text-align: start;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: none;
  padding: 4px 16px;
  width: 100%;
}

.menu-def-autocomplete-button:hover {
  background-color: #780096;
  color: white;
}

.menu-def-reorder-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.menu-def-reorder-modal-title {
  color: #2E2E2E;
  font-size: 18px;
  font-weight: 700;
}

.menu-def-reorder-modal-info {
  display: flex;
  flex-direction: row;
  background-color: #D3E1FC;
  color: #002C81;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  border-radius: 5px;
  gap: 16px;
}

.menu-def-reorderable-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F4F4F4;
  flex-direction: row;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 0px;
}

.menu-def-group-drag-icon {
  visibility: hidden;
}

.menu-def-reorderable-group:hover .menu-def-group-drag-icon {
  visibility: visible;
}

.menu-def-item-drag-icon {
  visibility: hidden;
}

.menu-def-reorderable-item:hover .menu-def-item-drag-icon {
  visibility: visible;
}

.menu-def-header-preview-button {
  border: none;
  background: none;
  z-index: 20;
}

.menu-def-group-dnd {
  max-height: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  display: none;
  transition: max-height .1s ease;
}

.menu-def-group-dnd.shown {
  min-height: 10px;
  max-height: 500px;
  overflow: auto;
  display: flex;
  transition: max-height .5s ease;
}

.menu-def-reorderable-item {
  padding: 4px;
  padding-left: 5%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}