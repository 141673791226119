.page-subtitle{
    height: 19px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #989898;
}

.checkbox-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;
    height: 20px;
    margin-top: 30px;
}

.reassign-button-container{
    display: flex;
    width: 99%;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 30px;
}

.return-button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.user-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 24px;
    gap: 10px;
    background: #F4F4F4;
    border-radius: 4px;
    width: 98%;
}

.user-info-container-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    height: 34px;
}

.form-grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
}

.grid-checkbox-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  padding: 32px;
  height: 50px;
}

.grid-checkbox-container:nth-child(4n+1),
.grid-checkbox-container:nth-child(4n+2) {
  background-color: #F4F4F4;
}

.grid-checkbox-container:nth-child(4n+3),
.grid-checkbox-container:nth-child(4n+4) {
  background-color: white;
}

.title-style{
  margin-bottom: 30px;
}

.text-style{
  margin-bottom: 40px;
}

.table-header{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
}