#case-toolbar-outer-container {
  width: 65%;
  flex-grow: 1;
}

#case-toolbar-inner-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  height: 80vh;
}

#case-toolbar-input-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.case-toolbar-form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-end;
}

.case-toolbar-option-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.case-toolbar-language-button {
  color: #2E2E2E;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  text-wrap: nowrap;
}

.case-toolbar-autocomplete-button {
  text-align: start;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: none;
  padding: 4px 16px;
  width: 100%;
  overflow: hidden;
}

.case-toolbar-autocomplete-button:hover {
  background-color: #780096;
  color: white;
}

.case-toolbar-link-button {
  font-size: 14px;
  font-weight: 600;
  border: none;
  background: none;
  color: #780096;
  text-decoration: underline;
}

#case-toolbar-reorder-modal-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  justify-content: space-between;
}

#case-toolbar-reorder-modal-inner {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  height: 400px;
}

#case-toolbar-reorder-modal-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

#case-toolbar-reorder-modal-right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

#case-toolbar-reorder-modal-groups {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  width: 100%;
  min-height: min-content;
  margin-bottom: 24px;
}

.case-toolbar-reorder-modal-group-option {
  background-color: #EEF2FF;
  width: 100%;
  padding: 8px;
  height: 36px;
}

#case-toolbar-reorder-modal-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  min-height: 32px;
}

#case-toolbar-reorder-modal-preview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  min-height: 32px;
}

#case-toolbar-reorder-modal-preview-title {
  background-color: #780096;
  color: white;
  text-align: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  width: 100%;
  font-weight: 700;
  padding: 8px;
}

#case-toolbar-reorder-modal-preview-items {
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.case-toolbar-reorder-modal-preview-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 24px;
  gap: 16px;
}

#case-toolbar-reorder-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.case-toolbar-item-draggable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
}

.case-toolbar-item-draggable:hover {
  background-color: #EFEFEF;
}

.case-toolbar-draggable-icon {
  visibility: hidden;
}

.case-toolbar-item-draggable:hover .case-toolbar-draggable-icon {
  visibility: visible;
}