:root {
  --custom-tabs-border-color: rgba(0, 0, 0, 0.12);
  --custom-tabs-color-default: rgba(0, 0, 0, 0.87);
  --custom-tabs-color: #a00095;
  --custom-tabs-hover-opacity: 0.8;
}

.custom-tabs__container {
  border-bottom: 2px solid var(--custom-tabs-border-color);
}

.custom-tabs__container .MuiTabs-indicator {
  height: 4px;
  border-radius: 2px;
}

.custom-tabs__tab {
  color: var(--custom-tabs-color-default);
  font-weight: 400;
}

.custom-tabs__tab--active {
  font-weight: bold;
}

.custom-tabs__tab:hover {
  color: var(--custom-tabs-color);
  opacity: var(--custom-tabs-hover-opacity);
}
