.queues-main-container {
  display: flex;
  flex-direction: column;
  padding: 6px 16px 0px 4px;
  gap: 32px;
}

.evenly-distributed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.plain-button {
  background: none;
  border: none;
}

.queue-admin-tab {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 75%;
  height: 60vh;
}

.form-flex {
  display: flex;
  width: 100%;
  gap: 16px;
}

.form-grid3 {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  width: 100%;
  gap: 16px;
}

@media (max-width: 1200px) {
  .form-grid3 {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    width: 100%;
    gap: 16px;
  }
}

@media (max-width: 800px) {
  .form-grid3 {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    width: 100%;
    gap: 16px;
  }
}
.queue-form-grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.option-button-pair {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.vertical-radio-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

#queue-assigned-users-show-button {
  border: none;
  background: none;
  background-color: gainsboro;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  gap: 8px;
}

.top-table-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.queue-user-checkbox {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  word-wrap: normal;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  }
  
  .queue-user-checkbox:nth-child(6n+1),
  .queue-user-checkbox:nth-child(6n+2),
  .queue-user-checkbox:nth-child(6n+3) {
    background-color: #F7F7F7;
  }
  
  .queue-user-checkbox:nth-child(6n+4),
  .queue-user-checkbox:nth-child(6n+5),
  .queue-user-checkbox:nth-child(6n+6) {
    background-color: white;
}

.queue-user-checkbox-image {
  border-radius: 50%;
  width: 34px;
    height: 34px;
}

#unassigned-users-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.form-select {
  gap: 2px
}
#queue-admin-warning-time-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.queue-admin-number-input-text-box {
  width: 65px;
  border-color: #780096;
}