.grammar-box {
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  border: none;
  border-radius: inherit;
  background-color: white;
  z-index: 75;
}

.grammar-box-gradient {
  border-radius: 4px;
  width: 319px;
  position: absolute;
  padding: 2px;
  background-image: linear-gradient(to bottom, #780096, #8051FF);
  z-index: 74;
}

.grammar-box-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.grammar-box-suggestions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 16px;
  padding: 16px 0px;
  margin-bottom: 16px;
  border-top: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
}

.grammar-box-suggestions-inner {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 256px;
  overflow: scroll;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.grammar-box-ignore-button {
  border: none;
  background: none;
  color: #780096;
  justify-self: flex-end;
  align-self: flex-end;
}

.grammar-box-triangle {
  position: absolute;
  top: -12px;
  left: 10%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  z-index: 1;
  border-bottom: 12px solid white;
}

.grammar-box-triangle-border {
  position: absolute;
  top: -15px;
  left: 10%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #780096;
}

.grammar-box-suggestion-option {
  border: none;
  background: none;
  color: #989898;
  font-weight: 500;
}

.grammar-box-suggestion-option:hover {
  border: none;
  background: none;
  color: #2E2E2E;
}