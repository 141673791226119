.tp-file-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 13px;
  border: 1px dashed #BFBFBF;
  border-radius: 4px;
  cursor: pointer;
  color: #2E2E2E;
}

.tp-file-selector-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 13px;
  align-items: center;
}

.tp-file-selector-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
}

.tp-file-selector-info {
  display: flex;
  flex-direction: column;
  text-wrap: wrap;
}

.tp-file-selector-size-info {
  font-size: 12px;
  color: #989898;
}

.tp-file-selector-button {
  color: #780096;
  cursor: pointer;
  text-decoration: underline;
}

.tp-file-selector-x-button {
  border: none;
  background: none;
}

.tp-file-selector-preview {
  height: auto;
  max-height: 100px;
  max-width: 150px;
}