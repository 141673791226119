.hot-display-license-info {
  display: none;
}
.handsontable table.htCore {
  border-collapse: collapse !important;
}

/* .ht_master.handsontable .wtHolder {
  height: 226px !important;
  overflow-y: auto;
  overflow-x: hidden;
  width: 983px !important;
} */
.menu-def-option-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.menu-def-main-form-container {
  width: 65%;
  flex-grow: 1;
}

.menu-def-main-form-inner-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;
  height: 80vh;
}
.menu-def-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
}
.menu-def-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  gap: 24px;
}
