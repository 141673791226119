#group-take-next {
  overflow: auto;
  height: 85vh;
  flex: 1;
}

#GTN-pending-cases-button {
  border: none;
  color: #3047B0;
  background-color: white;
}

.master-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 32px;
  overflow: auto;
}

#GTN-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #bfbfbf;
  padding: 8px;
  border-radius: 5px;
}

#GTN-main-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#GTN-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
}

#GTN-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

#GTN-status-select {
  border: none;
  background-color: #e6e6e6;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 32px;
  gap: 12px;
}

#GTN-status-toggle-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

#GTN-main-controls-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

#GTN-main-controls-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.GTN-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.GTN-checkbox-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.view-toggle {
  height: 38px;
    width: 38px;
  border: none;
  border-radius: 2px;
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.view-buttons {
  display: flex;
  flex-direction: row;
}

.GTN-lower-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#GTN-search-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  gap: 8px;
  border: 2px solid gainsboro;
}

.GTN-lower-controls-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  gap: 8px;
}

input:focus {
  outline: none;
}

.GTN-card {
  width: 100%;
  border: 1px solid #BFBFBF;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 24px;
  border-radius: 8px;
  gap: 16px;
}

.GTN-card-table {
  flex: 1;
  overflow: auto;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
}

.GTN-data-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.GTN-card-footer {
  display: flex;
    justify-content: space-between;
  }
  
  @media (max-width: 1400px) {
    .GTN-card-table {
      flex: 1;
      overflow: auto;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 1100px) {
    .GTN-card-table {
      flex: 1;
      overflow: auto;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(1, 1fr);
    }
}

.GTN-card-body {
  background-color: #F4F4F4;
  padding: 8px;
  gap: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}