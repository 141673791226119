.calendar-reminder {
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: min-content;
    background: none;
    padding: 4px;
    text-wrap: nowrap;
    color: black;
    align-items: center;
    font-size: 12px;
    scrollbar-width: none;
    width: 80%;
  }
  
  .reminder-case-id-button {
    width: min-content;
    color: black;
    background: none;
    border: none;
  }
  
  .reminder-case-id-button:hover {
    color: #780996;
    text-decoration: underline;
    font-weight: 700;
}