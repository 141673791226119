.inbound-azure-def-main-form-container {
    width: 65%;
    flex-grow: 1;
    background-color: white;
    border-radius: 8px;
  }
  
  .inbound-azure-def-main-form-inner-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 16px 16px 0px;
    margin-top: 10px !important;
  }
  
  .inbound-azure-def-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
  
  .inbound-azure-def-form-row {
    display: flex;
    gap: 24px;
    width: 100%;
  }
  
  .inbound-azure-def-option-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 32px;
  }
  
  .form-group {
    margin-bottom: 24px;
  }