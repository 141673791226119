.title-container{
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
}

.toast-info{
    /* Toaster notification XL No title */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    height: 54px;

    background: #D3E1FC;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;
}

.toast-info-inner {
    /* Frame 17153 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 100%;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.toast-info-text {
    /* Frame 17153 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 680px;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    color: #3047b0;

    font-weight: 600;

}