.checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  vertical-align: middle;
  gap: 8px;
}

.export-import-help-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border: none;
}

#additional-data-export-import-help {
  border: 2px solid gainsboro;
  border-radius: 4px;
}

#additional-data-export-import-help-button {
  border: none;
}

.alert-icon {
  color: mediumblue;
}

#add-data-admin-select-all-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

#additional-data-export-import-table {
  overflow-y: scroll;
  overflow-x: hidden;
  border: 3px solid gainsboro;
  border-radius: 4px;
  margin-bottom: 6px;
	height: 500px;
}

#additional-data-export-import-help {
  border: 2px solid gainsboro;
  border-radius: 4px;
}

#additional-data-export-import-help-button {
  border: none;
}

.export-import-help-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

#additional-data-bulk-add-row {
  background-color: white;
  border: none;
}
