/* Modal Container */
.custom-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure modal is on top of other content */
  height: "300px";
  overflow: "auto";
}

/* Modal Content */
.custom-modal-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  max-width: 80%; /* Adjust as needed */
  max-height: 80%; /* Adjust as needed */
  overflow-y: auto; /* Enable scrolling if content exceeds modal height */
  width: 60%;
  height: 55%;
}

/* CSS for Custom Modal Header */
.custom-modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.custom-modal-body {
  height: auto; /* Set height to auto */
  padding: 20px;
  /* Add additional styles as needed */
}


.focused-div:focus {   
  transition:border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}